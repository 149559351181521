<template>
  <div class="ticketList">
    <div class="ticketList_buttons">
      <a-radio-group v-model:value="gameType">
        <a-radio-button value="0">福彩</a-radio-button>
        <a-radio-button value="1">体彩</a-radio-button>
      </a-radio-group>
      <a-button type="primary" @click="router.push({path:'/shop-ticket-add'})">+新增票种</a-button>
    </div>
    <div class="ticketList_table">
      <m-table
        :pageSize="page.pageSize"
        :loading="loading"
        :buttons="buttons"
        :columns="columns"
        :data="list"
        @edit="edit"
        @del="del"
      ></m-table>
    </div>
  </div>
</template>

<script>
// import upLoadImg from "@/components/upLoadImg.vue";
import { getShopTicketListApi, delShopTicketApi } from "@/utils/api";
import { isJson, confirmDialog, showMessage } from "@/utils/util";
import { ref, reactive, toRefs, watch } from "vue";
import router from "@/router";
export default {
  // components: { upLoadImg },
  setup() {
    const gameType = ref("0"); //0福彩 1体彩
    const state = reactive({
      loading: false,
      list: [],
      columns: [
        { title: "ID", dataIndex: "id", key: "id" },
        { title: "名称", dataIndex: "name", key: "name" },
        {
          title: "图片",
          dataIndex: "mainImg",
          key: "mainImg",
          slots: { customRender: "image" }
        },
        { title: "进票单位", dataIndex: "unit", key: "unit" },
        { title: "零售单位", dataIndex: "retailUnit", key: "retailUnit" },
        { title: "零售价格", dataIndex: "ticketPrice", key: "ticketPrice" },
        { title: "标签", dataIndex: "tags", key: "tags" },
        { title: "tip", dataIndex: "tips", key: "tips" },
        {
          title: "操作",
          dataIndex: "button",
          key: "button",
          slots: { customRender: "actions" }
        }
      ],
      buttons: [
        { text: "编辑", eventName: "edit" },
        { text: "删除", eventName: "del" }
      ]
    });
    const page = {
      gameType: "0",
      pageNum: 1,
      pageSize: 20
    };

    async function loadData() {
      state.loading = true;
      const res = await getShopTicketListApi(page);
      if (res.code == 0) {
        state.loading = false;
        res.data.list.forEach(item => {
          if (isJson(item.detail)) {
            item.detailObj = JSON.parse(item.detail);
            if (item.detailObj.tags) {
              item.tags = item.detailObj.tags.join(",");
            }
            if (item.detailObj.tips) {
              item.tips = item.detailObj.tips;
            }
          }
        });
        state.list = res.data.list;
      }
      console.log(res);
    }
    loadData();

    watch(gameType, res => {
      page.gameType = res;
      loadData();
    });

    function edit(e) {
      router.push({ path: "/shop-ticket-add", query: { id: e.id } });
    }

    async function del(e){
      confirmDialog('提示',`确定要删除-${e.name}-吗？`)
      .then(async() => {
        const res = await delShopTicketApi(e.id)
        console.log(res);
        if(res.code == 0){
          showMessage('删除成功')
          loadData()
        }
      })
    }
    return {
      ...toRefs(state),
      router,
      gameType,
      page,
      edit,
      del
    };
  }
};
</script>

<style lang="scss" scoped>
.ticketList {
  .ticketList_buttons {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>